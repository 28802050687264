var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(_vm._s(_vm.pageheading.toLocaleUpperCase()))]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _vm.model === null || _vm.model.id === undefined
          ? _c("div", [_c("Loading")], 1)
          : _c("div", { attrs: { id: "body-content-area" } }, [
              _c(
                "form",
                {
                  attrs: { id: "pagelayout" },
                  on: { submit: _vm.handleFormSubmit },
                },
                [
                  _c("div", { staticClass: "columns" }, [
                    _c("div", { staticClass: "column is-two-thirds-desktop" }, [
                      _vm._m(0),
                      _c("div", { staticClass: "block" }, [
                        _c("div", [
                          _c("div", { staticClass: "field" }, [
                            _vm._m(1),
                            _c("div", { staticClass: "control" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.model.displayName,
                                    expression: "model.displayName",
                                  },
                                ],
                                staticClass: "input",
                                staticStyle: { "max-width": "75%" },
                                attrs: { type: "text" },
                                domProps: { value: _vm.model.displayName },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.model,
                                      "displayName",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm.reportTypes !== null
                            ? _c("div", { staticClass: "field" }, [
                                _vm._m(2),
                                _c(
                                  "div",
                                  { staticClass: "control" },
                                  [
                                    _c("Select", {
                                      ref: "documentType",
                                      staticStyle: { "max-width": "75%" },
                                      attrs: {
                                        options: _vm.reportTypes,
                                        default: _vm.model.typeCode,
                                        identifier: "documentType",
                                      },
                                      on: { onchange: _vm.handleSelectChange },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _vm._m(3),
                              _c("kendo-datepicker", {
                                staticStyle: { width: "200px" },
                                attrs: {
                                  id: "uploaddate",
                                  name: "uploaddate",
                                  required: "required",
                                  format: "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.model.uploadDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "uploadDate", $$v)
                                  },
                                  expression: "model.uploadDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "field" }, [
                            _c("label", { staticClass: "label is-size-7" }, [
                              _vm._v(" Document Year Override "),
                            ]),
                            _c("div", { staticClass: "control" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.model.yearOverride,
                                    expression: "model.yearOverride",
                                  },
                                ],
                                class: {
                                  input: true,
                                  "is-danger": _vm.inputError.yearOverride,
                                },
                                staticStyle: { "max-width": "75%" },
                                attrs: { type: "text" },
                                domProps: { value: _vm.model.yearOverride },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.model,
                                      "yearOverride",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.inputError &&
                                    _vm.inputError.yearOverride,
                                  expression:
                                    "inputError && inputError.yearOverride",
                                },
                              ],
                              staticClass:
                                "has-background-warning has-text-white",
                              staticStyle: {
                                padding: "0.5rem 1rem 1rem 1rem",
                                "margin-bottom": "1rem",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "has-text-weight-bold" },
                                [_vm._v("Error")]
                              ),
                              _c("ul", { attrs: { id: "example-1" } }, [
                                _c(
                                  "li",
                                  { staticStyle: { "margin-top": "5px" } },
                                  [_vm._v(_vm._s(_vm.inputError.yearOverride))]
                                ),
                              ]),
                            ]
                          ),
                          _vm.fileError.length !== 0
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    "padding-left": "1rem",
                                  },
                                },
                                _vm._l(_vm.fileError, function (error) {
                                  return _c(
                                    "div",
                                    {
                                      key: error,
                                      staticClass: "has-text-danger",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "min-width": "100%!important",
                                            margin: ".25rem 0rem",
                                          },
                                        },
                                        [_vm._v("Error: " + _vm._s(error))]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              ref: "filePondWrapper",
                              staticStyle: { "max-width": "75%" },
                            },
                            [
                              _vm.$hasPermissions(
                                _vm.clientSession,
                                ["DOCUMENT_CENTER"],
                                2
                              )
                                ? _c("file-pond", {
                                    ref: "filePond",
                                    attrs: {
                                      name: "file",
                                      "label-idle": _vm.filePondLabel,
                                      "allow-multiple": "true",
                                      "accepted-file-types":
                                        "application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                      files: _vm.input.files,
                                      dropOnElement: false,
                                      dropOnPage: true,
                                      allowMultiple: false,
                                      credits: [],
                                    },
                                    on: {
                                      addfile: _vm.addfile,
                                      removefile: _vm.removefile,
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.length > 0,
                                      expression: "errors.length>0",
                                    },
                                  ],
                                  staticClass:
                                    "has-background-warning has-text-white",
                                  staticStyle: {
                                    padding: "0.5rem 1rem 1rem 1rem",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "has-text-weight-bold" },
                                    [_vm._v("Error")]
                                  ),
                                  _c(
                                    "ul",
                                    { attrs: { id: "example-1" } },
                                    _vm._l(_vm.errors, function (err, idx) {
                                      return _c(
                                        "li",
                                        {
                                          key: err + idx,
                                          staticStyle: { "margin-top": "5px" },
                                        },
                                        [_vm._v(_vm._s(err))]
                                      )
                                    }),
                                    0
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "column is-one-third-desktop" }, [
                      _vm._m(4),
                      _c("div", { staticClass: "block" }, [
                        _c("div", [
                          _c("div", { staticClass: "field" }, [
                            _c(
                              "div",
                              { staticClass: "control" },
                              [
                                _c("Checkbox", {
                                  attrs: {
                                    isChecked: true,
                                    label: "Send email notification",
                                  },
                                  on: { isChecked: _vm.handleIsChecked },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm.emailTemplates !== null
                            ? _c("div", { staticClass: "field" }, [
                                _vm._m(5),
                                _c(
                                  "div",
                                  { staticClass: "control" },
                                  [
                                    _c("Select", {
                                      ref: "emailTemplate",
                                      staticStyle: { "max-width": "75%" },
                                      attrs: {
                                        options: _vm.emailTemplates,
                                        default: 1,
                                        identifier: "emailTemplate",
                                      },
                                      on: { onchange: _vm.handleSelectChange },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: { "padding-top": "40px" },
                          attrs: { id: "report-file-info-section" },
                        },
                        [
                          _vm._m(6),
                          _c(
                            "div",
                            {
                              staticClass: "block",
                              attrs: { id: "report-file-info-section-data" },
                            },
                            [
                              _c("div", [
                                _c(
                                  "table",
                                  { staticClass: "table is-size-7" },
                                  [
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "is-narrow",
                                          staticStyle: {
                                            "border-bottom": "none",
                                          },
                                        },
                                        [_vm._v("File Name:")]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "border-bottom": "none",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.fullname))]
                                      ),
                                    ]),
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "border-bottom": "none",
                                          },
                                        },
                                        [_vm._v("File Type:")]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "border-bottom": "none",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getFileType(
                                                _vm.original.extension
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "border-bottom": "none",
                                          },
                                        },
                                        [_vm._v("File Size:")]
                                      ),
                                      _vm.original.fileSize &&
                                      !isNaN(_vm.original.fileSize)
                                        ? _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "border-bottom": "none",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("prettyBytes")(
                                                    parseInt(
                                                      _vm.original.fileSize
                                                    )
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "border-bottom": "none",
                                          },
                                        },
                                        [_vm._v("Download:")]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "border-bottom": "none",
                                          },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "" +
                                                  _vm.getDocumentPath(
                                                    _vm.original
                                                  ),
                                                download: "",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-download fa-2x",
                                              }),
                                            ]
                                          ),
                                          _vm._v("   "),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "",
                                                target: "_blank",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.viewDocument(
                                                    _vm.original
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fas fa-eye fa-2x",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: { "padding-top": "20px" },
                      attrs: { id: "buttongroup" },
                    },
                    [
                      _c("div", { staticClass: "field is-grouped" }, [
                        _c("div", { staticClass: "control" }, [
                          _c(
                            "button",
                            {
                              ref: "saveButton",
                              class: [
                                _vm.isSaving ? "saving" : "",
                                "button is-accent has-text-white",
                              ],
                              attrs: {
                                type: "submit",
                                disabled:
                                  _vm.isSaving ||
                                  !_vm.$hasPermissions(
                                    _vm.clientSession,
                                    ["DOCUMENT_CENTER"],
                                    2
                                  ),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleFormSubmit.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.isSaving ? "Saving" : "Save Document"
                                ) + " "
                              ),
                              _vm.isSaving ? _c("spin-loader") : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "control" }, [
                          _c(
                            "a",
                            {
                              staticClass: "button is-light",
                              on: {
                                click: function ($event) {
                                  _vm.resetForm()
                                  _vm.$router.go(-1)
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
          "max-width": "75%",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Document Properties"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Label "),
      _c(
        "span",
        {
          staticClass: "has-text-danger",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Document Type "),
      _c(
        "span",
        {
          staticClass: "has-text-danger",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Upload Date "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Documents"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Email Template "),
      _c(
        "span",
        {
          staticClass: "has-text-danger",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          "margin-bottom": "10px",
          padding: "0px 0px px 0px",
          "border-bottom": "solid 1px lightgray",
        },
        attrs: { id: "report-file-info-section-heading" },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Document Information"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }